<template>
    <div class="f">
        <div class="features">
            <h1>More<br>Features</h1>
            <div v-for='i,index in data' :key="'card'+index">
                <dic class="card">
                    <div class="titles">
                        <img :src="i.minicons" class="minicons"/>
                        <!-- <div class="middle"> -->
                            <h2>{{i.title}}</h2>
                        <!-- </div> -->
                    </div>
                    <div class="content">
                        {{i.content}}
                    </div>
                </dic>
            </div>
        </div>
    </div>
</template>
<script>

export default ({
    data(){
        return{
            data:[{
                title:'From floorplans to virtual tours',
                minicons:require('@/assets/imgs/app/dragonVR/mini1.png'),
                content:'Change the way you present off-the-plan projects to potential buyers. In addition to floor plans, virtual walkthroughs allow buyers to visualise themselves living in the space.',
                },{
                title:'Customise your interiors',
                minicons:require('@/assets/imgs/app/dragonVR/mini2.png'),
                content:'Have a specific interior design vision which compliments your developments image? DragonVR can customise interiors to suit specific styles, eras and trends.',
                },{
                title:'Substantial saving on time and money',
                minicons:require('@/assets/imgs/app/dragonVR/mini3.png'),
                content:'In comparison to display suites, virtual reality renders can put developers ahead by months when it comes to project marketing sales, saving them hundreds of thousands of dollars.',
                },{
                title:'Gain VR inclusions in certain Arcanite subscription packages',
                minicons:require('@/assets/imgs/app/dragonVR/mini4.png'),
                content:'Arcanite offers free VR credits on its premium and enterprise packages to get your projects noticed for all the right reasons.',
                }
                ,{
                title:'Simple sharing via QR codes',
                minicons:require('@/assets/imgs/app/dragonVR/mini5.png'),
                content:'QR codes are a quick and easy way to share project rendering to clients. Scan the QR code to experience a virtual walkthrough created by the DragonVR team.',
                }
            ]
        }
    },
    setup() {
        
    },
})
</script>
<style lang="scss" scoped>
    .f{
        width: 100%;
        // height: 744px;
        display: block;
        overflow-x: hidden;
        margin-top: 50px;
    }
    .features{
        margin:auto;
        width:100%;
        // height: 744px;
        // background-image: url('./../../assets/imgs/app/homepage/features.png');
    }
    h1{
       margin:auto;
    //    width: 323px;
    //    padding: 10px;
       padding-left: 26px;
        font-family: Poppins-Bold;
        font-size: 36px;
        // font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        color: #062440;
        margin-bottom: 24px;
    }
    .card{
        margin:10px 26px 10px 26px;
        // width: 323px;
        // padding: 10px;
        display: inline-block;
        margin-bottom: 20px;
    }
    .titles{
        margin-bottom: 10px;
        display: flex;
        align-items: center;/*垂直居中*/
        // display: inline-block;
        img{
            width: 34px;
            height:34px;
            margin-right: 10px;
        }
        .minicons{
            width: 48px;
            height: 48px;
        }
        .middle{
            // display: table
        }
        h2{
            // display:table-cell;
            vertical-align:middle;
            font-family: Poppins-Bold;
            font-size: 16px;
            // font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: left;
            color: #062440;
            // height: 44px;
            // display: inline;
            // vertical-align: middle;
        }
    }
    .content{
        margin-top: 10px;
        // margin-left: 10px;
        // width: 303px;
        font-family: Poppins;
        padding:0 10px 10px 10px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #062440;
    }
</style>
